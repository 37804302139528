import os from 'os';
import { useHistory } from 'react-router-dom';
import { useState } from 'react';

const Pendek = (props) => {
  const history = useHistory();
  const [isCopied, setIsCopied] = useState(false);

  const url = props.location.state.url.data;
  const fullUrl = url.fullUrl;
  // const shortUrl = `https://pendek.me/${url.shortUrl}`;
  const shortUrl = `https://${os.hostname()}/${url.shortUrl}`;

  return (
    <div className='center'>
      <h1 className='title'>pendek.me</h1>
      <input id='shorten-url' type='url' value={shortUrl} readOnly />
      {isCopied && <p>URL Copied to Clipboard&#9989;</p>}
      <button
        className='btn btn-primary'
        onClick={() => {
          navigator.clipboard.writeText(shortUrl);
          setIsCopied(true);
        }}
      >
        Copy URL
      </button>
      <button className='btn btn-primary' onClick={() => history.push('/')}>
        Create New
      </button>
      <p>
        Go to:{' '}
        <a rel='noreferrer' href={fullUrl} target='_blank'>
          {fullUrl}
        </a>
      </p>
    </div>
  );
};

export default Pendek;
